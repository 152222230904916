import axios from "axios";
import store from "@/store";

const PollsApi = {
  init() {
    // axios.defaults.baseURL = store.state.API_ROOT;
    // axios.defaults.headers.post["x-rocket-token"] = store.state.API_TOKEN;
    // axios.defaults.withCredentials = true; // Si no se pone no se manda la `cookie-session`
  },
  getPoll(id) {
    // console.log("Poll -> ", id);
    const url = `${store.state.API_ROOT}/api/polls/${id}`;
    return new Promise((resolve, reject) => {
      axios.get(url, { params: {}, headers: { "x-rocket-token": store.state.API_TOKEN } })
        .then((res) => {
          resolve(res.data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  vote(id, query = {}) {
    // console.log("Vote -> ", id, query);
    const url = `${store.state.API_ROOT}/api/polls/${id}/vote`;
    return new Promise((resolve, reject) => {
      axios.post(url, query, { headers: { "x-rocket-token": store.state.API_TOKEN } })
        .then((res) => {
          // console.log("Privilege post", res);
          resolve(res.data);
        })
        .catch(e => {
          console.log("Vote post ERR", e);
          reject(e);
        });
    });
  }
};

export default PollsApi;
