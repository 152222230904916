<template>
  <div class="row bg-light p-2 rounded" style="margin-right: 0;margin-left: 0;">
    <div class="p-4 p-md-5 border rounded-3 bg-light col-lg-6 col-md-12 mx-auto">
      <h3 class="display-7 fw-bold lh-1 mb-3">
        {{ question }}
      </h3>
      <p v-if="timeLeft > -1" class="text-muted mb-1">
        <iconify-icon icon="svg-spinners:clock" /> {{ timeLeft }} s.
      </p>
      <ul class="list-group">
        <li class="list-group-item" v-for="(it, idx) in options" :key="idx"
          :class="{ active: choice == idx, disabled: timeLeft <= 0 }" @click="voteClicked(idx)">
          <p class="col-lg-10 fs-5 text-center">
            <img class="poll-img pt-3 " :src="it.media" :alt="it.answer">
            <br>
            {{ it.answer }}
          </p>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      choice: -1,
      timeLeft: -1,
      timer: null
    };
  },
  props: {
    pollid: {
      type: String,
      required: true,
      default: ""
    },
    question: {
      type: String,
      required: true,
      default: ""
    },
    options: {
      type: Array,
      default() {
        return [];
      }
    },
    voteDisabled: {
      type: Boolean,
      default: false
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    poll_token: {
      type: String,
      default: ""
    }
  },
  methods: {
    decodeJWT(token) {
      try {
        const base64Url = token.split(".")[1];
        const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
        const jsonPayload = decodeURIComponent(atob(base64).split("").map(c => `%${(`00${c.charCodeAt(0).toString(16)}`).slice(-2)}`).join(""));
        return JSON.parse(jsonPayload);
      }
      catch (e) {
        console.warn("JWT ERROR:", e);
        return {};
      }
    },
    calculateTimeLeft() {
      if (!this.poll_token) return -1;

      try {
        const decoded = this.decodeJWT(this.poll_token);
        const remaining = Math.floor((decoded.exp * 1000 - Date.now()) / 1000);
        return remaining > 0 ? remaining : 0;
      }
      catch (e) {
        console.warn("Error calculando tiempo:", e);
        return 0;
      }
    },
    showExpiredAlert() {
      if (this.timer) {
        clearInterval(this.timer);
      }
      this.$swal({
        icon: "info",
        title: "¡Se acabó tu tiempo para votar!",
        text: "Escanea el QR nuevamente",
        confirmButtonText: "Entendido",
        confirmButtonColor: "rgb(65, 184, 130)"
      });
    },
    startTimer() {
      if (this.timer) {
        clearInterval(this.timer);
      }

      this.timeLeft = this.calculateTimeLeft();

      if (this.timeLeft <= 0) {
        this.showExpiredAlert();
        return;
      }

      this.timer = setInterval(() => {
        this.timeLeft = this.calculateTimeLeft();
        if (this.timeLeft <= 0) {
          this.showExpiredAlert();
        }
      }, 1000);
    },
    voteClicked(idx) {
      if (this.timeLeft <= 0) {
        this.$swal({
          icon: "warning",
          title: "Tiempo agotado",
          text: "Escanea el QR nuevamente para votar",
          confirmButtonText: "Entendido",
          confirmButtonColor: "rgb(65, 184, 130)"
        });
        return;
      }

      const { answer } = this.options[idx];
      this.$swal({
        icon: "question",
        title: `¿Votar por ${answer}?`,
        showDenyButton: true,
        confirmButtonText: "¡Votar!",
        denyButtonText: " No ",
      }).then(res => {
        if (res.isConfirmed) {
          this.$emit("onVote", this.pollid, idx);
        }
      });
      this.$emit("onTryVote", this.pollid, idx);
    }
  },
  watch: {
    poll_token: {
      immediate: true,
      handler(newToken) {
        if (newToken) {
          this.startTimer();
        }
      }
    }
  },
  beforeDestroy() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  }
};
</script>

<style scoped>
.poll {}

.poll-img {
  max-height: 200px;
  width: 100px;
  /* display: none; */
}

.list-group-item.disabled {
  cursor: not-allowed;
  opacity: 0.7;
}
</style>
