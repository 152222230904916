import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);
const API_ROOT = process.env.VUE_APP_APIROOT;
const WS_ROOT  = process.env.VUE_APP_WSAPI;
const API_TOKEN  = process.env.VUE_APP_APITOKEN;

export default new Vuex.Store({
  state: {
    API_ROOT,
    WS_ROOT,
    API_TOKEN,
    routes: [],
    pollpin: "",
    polldate: "",
    polls: []
  },
  mutations: {
    // Mutations con inicial Mayuscula
    SetRoutes(state, routes) {
      Vue.set(state, "routes", [...routes, ...state.routes]);
    },
    SetPollPin(state, tok) {
      state.pollpin = tok;
    },
    SetPollDate(state, val) {
      state.polldate = val;
    },
    InsertPoll(state, val) {
      let { polls = [] } = state;
      const { pollpin = null } = val;
      if (!pollpin) {
        return;
      }

      const pollsFound = polls.filter((ele) => ele.pollpin === pollpin);
      if (pollsFound.length > 0) {
        polls = polls.map((ele) => (ele.pollpin === pollpin ? val : ele));
      }
      else {
        polls.push(val);
      }
      state.polls = polls;
    },
    InitStore(state) {
      if (!localStorage.getItem("ky_store")) {
        console.log("No local storage found");
        return;
      }

      try {
        let stObj = JSON.parse(localStorage.getItem("ky_store"));

        const nooverwrite =  ["API_ROOT", "WS_ROOT", "API_TOKEN"];
        stObj = Object.keys(stObj).reduce((prev, curr) => {
          if (nooverwrite.indexOf(curr) === -1) {
            // eslint-disable-next-line no-param-reassign
            prev[curr] = stObj[curr];
          }
          return prev;
        }, {});

        this.replaceState(Object.assign(state, stObj));
      }
      catch (e) {
        console.warn("InitReplacing store FAILED!", localStorage.getItem("ky_store"));
      }
    }
  },
  actions: {
    RegisterRoute({ commit }, r) {
      commit("SetRoutes", [r]);
    },
  }
});
