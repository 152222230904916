<style>
@media print {
  svg.image, footer {
    display: none;
  }
  .bg-darkadcast > * {
  color: black !important;
  }
}
.cookiewarn {
  opacity: 0.95;
}
footer {
  line-height: unset !important;
  font-size: 0.9em;;
}
</style>
<template>
  <div class="d-flex flex-column h-100 container">
  <section class="poll flex-shrink-0" id="home">
  <router-view>
  </router-view>
  </section>
  <footer class="footer mt-auto py-3 bg-light">
    <div class="container">
      <!-- <a href="#"> -->
      <span class="text-muted">Ésta página no recopila datos personales.</span>
      <!-- </a> -->
    </div>
  </footer>
  </div>

</template>

<script>
import "bootstrap";
import "bootstrap/dist/js/bootstrap.min";
import "bootstrap/dist/css/bootstrap.min.css";

import { mapMutations, mapState } from "vuex";

export default {
  name: "App",
  components: {

  },
  data() {
    return {

    };
  },
  computed: {
    ...mapState(["API_ROOT"])
  },
  methods: {
    // eslint-disable-next-line dot-notation
    // ...mapMutations["SetAcceptCookies"],

  },
  mounted() {}
};
</script>
