import Vue from "vue";
import { Icon } from "@iconify/vue2";
import VueSweetalert2 from "vue-sweetalert2";
import VueGtag from "vue-gtag";
import App from "./App.vue";
import store from "./store";
import router from "./router";
// If you don't need the styles, do not connect
import "sweetalert2/dist/sweetalert2.min.css";
// import "@/assets/adcast.css";

// eslint-disable-next-line no-multi-assign
window.$ = window.jQuery = window.jquery = require("jquery");

Vue.config.productionTip = false;

Vue.component("iconify-icon", Icon);

Vue.use(VueGtag, {
  config: { id: "G-38SG9J0LRN" }
}, router);

Vue.use(VueSweetalert2, {
  confirmButtonColor: "#41b882",
  cancelButtonColor: "#ff7674",
  reverseButtons: true,
  heightAuto: false  // Esto descompone el CSS de <body>, dejar en `false`
});
new Vue({
  store,
  router,
  render: h => h(App),
  beforeCreate() {
    this.$store.commit("InitStore");
  },
  mounted() {
    store.subscribe((mutation, state) => {
      // Store the state object as a JSON string
      localStorage.setItem("ky_store", JSON.stringify(state));
    });
  }
}).$mount("#app");
