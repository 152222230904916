<template>
<div class="" style="margin-right: 0;margin-left: 0;">
    <PollView
      v-if="!showResults"
      class="col-12"
      :pollid="poll"
      :question="question"
      :options="optionList"
      :vote-disabled="showResults"
      :isLoading="isLoading"
      :poll_token="poll_token"
      @onVote="voteClicked"
      @onTryVote="tryVote"
    >
    </PollView>
    <PollVote
      v-if="showResults"
      class="col-12"
      :pollid="poll"
      :question="question"
      :options="optionList"
    >
    </PollVote>
</div>
</template>

<script>
import { mapMutations, mapState } from "vuex";

import PollView from "@/components/PollView.vue";
import PollVote from "@/components/PollVote.vue";
import PollsApi from "@/services/Polls.service";

export default {
  name: "PublicDash",
  components: {
    PollView,
    PollVote
  },
  data() {
    return {
      poll_token: "",
      poll: "",
      question: "",
      user: "",
      optionList: [],
      showResults: false,
      isLoading: false
    };
  },
  computed: {
    ...mapState([
      "API_ROOT",
      "pollpin",
      "polldate",
      "polls"
    ])
  },
  watch: {
    $route: {
      handler(to, from) {
        // const { pin } = this.$route.query;
        // console.log("Route changes", from, to, to.params.id);
        // console.log("Route changes PIN", pin);
        if (typeof to.params.id === "undefined") {
          this.poll = ""; // hide single
          if (this.pollpin) {
            this.$router.push(`/${this.pollpin}`);
          }
          return;
        }

        this.poll = to.params.id;
        PollsApi.getPoll(this.poll)
          .then((res) => {
            const {
              question = "", options = [], _id: poll = "", activeDate = "", owner = {}, poll_token: token = ""
            } = res;
            const { name: user = "" } = owner;
            this.user = user;

            // console.log("POLL:", res);
            const storePolls = this.polls.filter(p => (p.pollpin && p.polldate && p.pollpin === poll && p.polldate === activeDate));
            if (storePolls.length > 0) {
              this.showResults = true;
            }

            this.poll = poll;
            this.question = question;
            this.optionList = options;
            this.poll_token = token;

            this.$gtag.event(`kyvisit-${this.user}`, {
              event_category: "kyvisit",
              event_label: this.user,
              value: undefined,
              non_interaction: true,
              page_location: window.location.href
            });
          })
          .catch((error) => {
            // console.log(error);
            this.$swal({
              icon: "warning",
              title: "Encuesta desactivada",
              text: "Inténtelo nuevamente mas tarde",
              // toast: true,
              // timer: 4000,
              // timerProgressBar: true,
              // position: "bottom-end",
              // showConfirmButton: false
            });
          });
      },
      immediate: true
    }
  },
  methods: {
    ...mapMutations([
      "SetPollPin",
      "SetPollDate",
      "InsertPoll"
    ]),
    voteClicked(id, choice) {
      // console.log("vote", id, choice);
      this.isLoading = true;
      PollsApi.vote(this.poll, { options: { idx: choice }, poll_token: this.poll_token })
        .then((res) => {
          const {
            question = "", options = [], activeDate = ""
          } = res;
          this.SetPollPin(this.poll);
          this.SetPollDate(activeDate);
          this.InsertPoll({
            question,
            pollpin: this.poll,
            polldate: activeDate,
            vote: choice
          });
          this.question = question;
          this.optionList = options;
          this.showResults = true;
          this.isLoading = false;
          // console.log("VOTED", res);
          this.$swal({
            icon: "success",
            title: "Voto registrado",
            text: "Gracias por su participación",
            timer: 5000,
            timerProgressBar: true,
            // toast: true,
            // position: "bottom-end",
            // showConfirmButton: false
          });

          this.$gtag.event(`kyvote-${this.user}`, {
            event_category: "kyvote",
            event_label: this.user,
            value: choice,
            non_interaction: true,
            page_location: window.location.href
          });
        })
        .catch((error) => {
          // console.log(error);
          this.isLoading = false;
          this.$swal({
            icon: "warning",
            title: "No se pudo registrar voto",
            text: "Inténtelo nuevamente mas tarde",
            // toast: true,
            // timer: 4000,
            // timerProgressBar: true,
            // position: "bottom-end",
            // showConfirmButton: false
          });
        });
    },
    tryVote(id, choice) {
      this.$gtag.event(`kytryvote-${this.user}`, {
        event_category: "kytryvote",
        event_label: this.user,
        value: choice,
        non_interaction: true,
        page_location: window.location.href
      });
    }
  },
  mounted() {}
};
</script>
