import Vue from "vue";
import VueRouter from "vue-router";
// Rocket Core
// import Logs   from "../views/Logs.vue";

import PublicDash  from "../views/PublicDash.vue";
// import AdcastAdmin from  "../views/AdcastAdmin.vue";
// import AdcastAdlog from  "../views/AdcastAdlog.vue";
// import AdcastPaymentReturn from  "../views/AdcastPaymentReturn.vue";
// import store from "../store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "EDIS Kitoy",
    component: PublicDash,
  },
  {
    path: "/:id",
    name: "EDIS Kitoy",
    component: PublicDash
  },
  // {
  //   path: "/status",
  //   name: "AdcastAdlog",
  //   component: AdcastAdlog,
  // },
  // {
  //   path: "/status/:code",
  //   name: "AdcastAdlog",
  //   component: AdcastAdlog,
  // },
  // {
  //   path: "/payment",
  //   name: "AdcastPaymentReturn",
  //   component: AdcastPaymentReturn,
  // },
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
});
// router.beforeEach((to, from, next) => {
//   // FIXME: Cuando las rutas están anidadas to.matched es un array y
//   //        hay que checar con todos los matches

//   const { privileges:userPrivs } = store.state;
//   const { token = null } = store.state;
//   const routePrivs = to.meta.privileges ? to.meta.privileges : ["ROCKET-USER"];

//   // console.log("ROUTE", to.name, userPrivs, userPrivs.length, ", route asks: ", routePrivs);
//   if (to.name === "Home" || !to.meta || !to.meta.privileges || to.meta.privileges.length < 1) { // Public route
//     // console.log("Bypass ROUTE", to.name, userPrivs, userPrivs.length, ", route asks: ", routePrivs);
//     next();
//     return;
//   }

//   if (!token || userPrivs == null || userPrivs.length < 1) { // En caso de que aún no haya logins
//     // console.log("ROUTER, NO LOGIN DETECTED");
//     next(false);
//     return;
//   }

//   // console.log("ROUTE PROTECT: I have ", userPrivs, userPrivs.length, ", route asks: ", routePrivs, "ALLOW ? Letmethink");

//   const [shortArr, longArr] = (userPrivs.length < routePrivs.length) ? [userPrivs, routePrivs] : [routePrivs, userPrivs];
//   // console.log("LONGER", longArr, "SHORTER", shortArr);
//   const longArrSet = new Set(longArr);
//   const enough = shortArr.some(el => longArrSet.has(el));
//   // console.log("ROUTE PROTECT: I have ", userPrivs, ", route asks: ", routePrivs, "ALLOW ?", enough);
//   if (enough) {
//     next();
//     return;
//   }

//   // console.log("ROUTE TO DEFAULT /");
//   // OJO: Redirigir a una ruta redirigida puede acabar en otro lado (o en loops)
//   next("/"); // Reject navigation, o por nombre `next({ name: 'Login' })` o `next(false)`
// });

export default router;
