<style scoped>
</style>

<template>
<div class="row bg-light p-2 rounded" style="margin-right: 0;margin-left: 0;">
  <div class="p-4 p-md-5 border rounded-3 bg-light">
    <h3 class="display-7 fw-bold lh-1 mb-3">
    {{question}}
    </h3>
    <div v-for="(it, idx) in options" :key="idx">
      <div class="d-flex justify-content-between">
        <p class="fs-5 pt-4 mb-0">
          {{it.answer}}
        </p>
        <p class="fs-5 pt-4 mb-0">
          {{calculatePorcen(totalVotes, it.votes)}}%
        </p>
      </div>
      <div class="progress">
        <div
          class="progress-bar progress-bar-striped progress-bar-animated"
          role="progressbar"
          aria-valuenow="75"
          aria-valuemin="0"
          aria-valuemax="100"
          :style="`width: ${calculatePorcen(totalVotes, it.votes)}%`">
        </div>
      </div>
    </div>
  </div>
</div>

</template>
<script>
export default {
  data() {
    return {};
  },
  props: {
    pollid: {
      type: String,
      required: true,
      default: ""
    },
    question: {
      type: String,
      required: true,
      default: ""
    },
    options: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  computed: {
    totalVotes() {
      const { options = [] } = this;
      const total = options.reduce((prev, curr) => prev + curr.votes, 0);
      return total;
    }
  },
  methods: {
    calculatePorcen(total, value) {
      if (!total || total === 0) {
        return 0;
      }
      // eslint-disable-next-line no-mixed-operators
      const porcen = (value * 100 / total);
      return porcen.toFixed(1);
    },
  },
  mounted() {}
};
</script>
